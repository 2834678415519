<template>
    <v-treeview 
        :items="formattedData"
        dense
    />
</template>

<script>
export default {
    data: () => ({
        id: 1
    }),
    props: {
        data: {
            type: Object,
            required: true
        },
        translateData: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formattedData() {
            return this.format(this.data)
        }
    },
    methods: {
        format(data) {
            let formattedData = []
            for (const [key, value] of Object.entries(data)) {
                let formattedObject = {
                    id: this.id++,
                    name: key
                }
                if (typeof value == 'object') {
                    formattedObject.children = this.format(value)
                }
                else {
                    formattedObject.name = `${key}:  ${value}`
                }
                formattedData.push(formattedObject)
            }
            return formattedData
        }
    }

}
</script>