<template>
    <div>
        <ConfirmationDialog 
            v-model="showRestoreWarning"
            :title="$t('FeatureEditor.undo_all')"
            @confirm="restoreAllProperties()"
        >
            {{$t('FeatureEditor.undo_all_warning')}}
        </ConfirmationDialog>
        <v-card 
            :rounded="isMobileSize() ? '0' : null"
            :elevation="isMobileSize() ? 0 : 2"
            
            :class="isMobileSize() ? 'pa-0' : 'pt-0'"

        >
            <v-card-title class="header">
                <v-btn
                    v-if="isMobileSize()"
                    @click="deselect"
                    icon
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>{{ translate(title) }}</span>
                <InfoButton
                    :text="translate(feature.info.description)"
                    :href="feature.info.href"
                />
                <v-spacer/>
                <v-btn 
                    @click="showRestoreWarning = true" 
                    :disabled="!dirty"
                    icon
                >   
                    <v-icon>mdi-arrow-u-left-top-bold</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-4">
                <div v-for="property, propertyName in editableProperties" :key="propertyName">
                    <GenerativeInput 
                        :featureName="featureName"
                        :propertyGroupName="propertyGroupName"
                        :propertyName="propertyName" 
                        :property="property" 
                        :propertiesAreSelectable="propertiesAreSelectable"
                        :previousSubheader="getPreviousHeader(propertyName)"
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import GenerativeInput from '@/components/GenerativeInput.vue'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import InfoButton from './inputs/subcomponents/InfoButton.vue'

import stMinVersionMet from '@/utils/stMinVersionMet.js';
import stMaxVersionMet from '@/utils/stMaxVersionMet.js';

import { getProperty } from 'dot-prop';

export default {
    components: {
        GenerativeInput,
        ConfirmationDialog,
        InfoButton
    },
    props: {
        propertiesAreSelectable: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showRestoreWarning: false
    }),
    computed: {
        versions() {
            return this.$store.getters.versions
        },
        featureName() {
            return this.$store.getters.selected.featureName
        },
        propertyGroupName() {
            return this.$store.getters.selected.propertyGroupName
        },
        feature() {
            return this.$store.getters.features[this.featureName]
        },
        featureIsUndefined() {
            let currentFeatureNames = Object.keys(this.$store.getters.config.desired)
            return currentFeatureNames.indexOf(this.featureName) == -1
        },
        propertyGroup() {
            if(this.propertyGroupName) {
                return this.feature.property_groups[this.propertyGroupName]
            }
            return null
        },
        title() {
            return this.propertyGroup ? this.propertyGroup.title : this.feature.title
        },
        byteGroups() {
            return this.propertyGroup ? this.propertyGroup.byte_groups : this.feature.byte_groups
        },
        editableProperties() {
            let editableProperties = {}
            this.byteGroups.forEach(byteGroup => {
                let properties = byteGroup.properties
                Object.keys(properties).forEach(propertyName => {
                    let property = properties[propertyName]
                    if(!property.hidden && property.input_type && this.propertyVersionsRequirementsMet(property.min_version, property.max_version)) {
                        let dependencies = property.dependencies 
                        if(dependencies) {
                            let allDependenciesMet = dependencies.every(dependency => {
                                let propertyValue = this.$store.getters.property(dependency.if.feature, dependency.if.property_group, dependency.if.property)
                                if(dependency.oneOf.includes(propertyValue)) {                                   
                                    return true
                                }
                                return false
                            })
                            if(allDependenciesMet) {
                                editableProperties[propertyName] = property
                            }
                        }
                        else {
                            editableProperties[propertyName] = property
                        }
                    } 
                })
            })
            return editableProperties
        },
        dirty() {
            return this.$store.getters.dirty(this.featureName, this.propertyGroupName)
        }
    },
    methods: {
        restoreAllProperties() {
            let payload = {
                featureName: this.featureName,
                propertyGroupName: this.propertyGroupName,
            }
            this.$store.dispatch("restoreAllProperties", payload)
        },
        deselect() {
            this.$store.dispatch("setSelected", null)
        },
        propertyVersionsRequirementsMet(minVersions, maxVersions) {
            if(minVersions == null) {
                return true
            }
            let stMin = getProperty(minVersions, 'st')
            let stMax = getProperty(maxVersions, 'st')
            return stMinVersionMet(this.versions.st, stMin) && stMaxVersionMet(this.versions.st, stMax)
        },
        getPreviousHeader(propertyName) {
            let propertyNamesArray = Object.keys(this.editableProperties)
            let previousIndex = propertyNamesArray.indexOf(propertyName) - 1
            if(previousIndex >= 0) {
                let previousPropertyName = propertyNamesArray[previousIndex]
                return this.editableProperties[previousPropertyName].subheader
            }
            return undefined
        }
    }
}
</script>