<template>
    <div>
        <ConfirmationDialog 
            v-model="showRestoreWarning"
            :title="$t('FeatureSelector.restore.title')"
            @confirm="confirmRestoreFeature(featureNameToRestore)"
        >
            {{ $t('FeatureSelector.restore.text') }}
        </ConfirmationDialog>
        <div v-if="noFeaturesAreMissing">
            <v-list dense>
                <v-subheader class='text-wrap' style="word-break: break-word">
                    {{ $t('FeatureSelector.absent') }}
                </v-subheader>
                <v-list-item v-for="feature, featureName in featuresNotInDesired" :key="featureName">
                    <v-list-item-icon>
                        <v-icon>{{ feature.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ feature.title[$i18n.locale] }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon @click="addMissingFeatureToConfig(featureName)" color="primary">mdi-plus</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
            <v-divider/>
        </div>
        <v-list dense>
            <v-subheader class='text-wrap' style="word-break: break-word">
                {{ $t('FeatureSelector.present') }}
            </v-subheader>
            <v-list-item v-for="feature, featureName in featuresInDesired" :key="featureName">
                <v-list-item-icon>
                    <v-icon>{{ feature.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ feature.title[$i18n.locale] }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon v-if="!allPropertiesAreOnDefault(featureName)">
                    <v-icon @click="restoreFeatureClick(featureName)" color="primary">mdi-restore</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue"

export default {
    components: {
        ConfirmationDialog
    },
    data: () => ({
        showRestoreWarning: false,
        featureNameToRestore: null
    }),
    computed: {
        featureNamesInDesired() {
            return this.$store.getters.featureNamesInDesired
        },
        features() {
            return this.$store.getters.features
        },
        featuresInDesired() {
            let featuresInDesired = {}
            Object.keys(this.features).forEach(featureName => {
                if(this.featureNamesInDesired.includes(featureName)) {
                    featuresInDesired[featureName] = this.features[featureName]
                }
            })
            return featuresInDesired
        }, 
        featuresNotInDesired() {
            let featuresNotInDesired = {}
            Object.keys(this.features).forEach(featureName => {
                if(!this.featureNamesInDesired.includes(featureName)) {
                    featuresNotInDesired[featureName] = this.features[featureName]
                }
            })
            return featuresNotInDesired
        },
        noFeaturesAreMissing() {
            return Object.keys(this.featuresNotInDesired).length > 0
        }
    },
    methods: {
        addMissingFeatureToConfig(featureName) {
            this.$store.dispatch("addMissingFeatureToConfig", featureName)
        },
        setFeatureToDefault(featureName) {
            this.$store.dispatch("setFeatureToDefault", featureName)
        },
        restoreFeatureClick(featureName) {
            this.featureNameToRestore = featureName
            this.showRestoreWarning = true 
        },
        confirmRestoreFeature(featureName) {
            this.setFeatureToDefault(featureName)
            this.featureNameToRestore = null
        },
        allPropertiesAreOnDefault(featureName) {
            return this.$store.getters.allPropertiesAreOnDefault(featureName)
        }
    }
}
</script>