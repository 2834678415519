export default function stMaxVersionsMet(stCurrent, stMaxVersion) {
    if(stMaxVersion == null) {
        return true
    }

    let stCurrentSplit = stCurrent.split('.').map(Number)
    let stMaxVersionSplit = stMaxVersion.split('.').map(Number)
    return stMaxVersionSplit.every(function (requiredMaxVersion, index) {
        return stCurrentSplit[index] <= requiredMaxVersion
    })
}